import HairOil from '../components/Assets/img/Hair-oil.jpg';
import BodyLotion from '../components/Assets/img/body-lotion.jpg';
import FootSoak from '../components/Assets/img/Foot-soak.jpg';
import BathPowder from '../components/Assets/img/Bath-powder.jpg';
import BodyButter from '../components/Assets/img/Body-butter.jpg';
import LipButter from '../components/Assets/img/lip-butter.jpg';
import FaceCream from '../components/Assets/img/Face-cream.jpg';
import FootButter from '../components/Assets/img/Foot-cream.jpg';
import Kajal from '../components/Assets/img/Kajal.jpg';
import LipBalm from '../components/Assets/img/lip-balm.jpg';
import Nattusakkarai from '../components/Assets/img/nattu-sakkarai.jpg'

export const products = {
  1: {
    image: HairOil,
    title: 'Herbal Hair Oil',
    category: 'haircare',
    price: '260.00',
    priceSize: '(230 ml)',
    description: 'Hair oil made with 100% fresh herbs. It controls dandruff and hair fall. Nourishes the scalp.',
    description1: '<strong>Ingredients</strong>: Coconut Oil, Vettiver, Marudhani leaf, Amla, Neem Leaves, Fenugreek, Hibiscus, Bhringraj, Aloe Vera, Almond, Piper Nigrum, Edible Camphor.',
    description2: '<strong>Benefits</strong>: Controls dandruff and hair fall. Nourishes the scalp. Prevents pre-mature greying of hair. Adds smoothness and shine.',
    longDescription: '<strong>Directions</strong>: Apply the oil to the scalp and massage gently. Leave it for 2 hours or overnight, then wash with hair wash powder or shampoo. Use at least thrice a week for best results.',
  },
  2: {
    image: BodyLotion,
    title: 'Body Lotion',
    category: 'skincare',
    price: '325.00',
    priceSize: '(100 ml)',
    description: 'A premium body lotion enriched with natural ingredients to keep your skin hydrated and supple.',
    description1: '<strong>Ingredients</strong>: Shea butter, Almond oil, Jojoba Oil, Rose Hydrosol, Veg Glycerine, Emulsifying Wax, Cetyl Alcohol, Essential Oils, Fragrance & Preservative.',
    description2: '<strong>Benefits</strong>: Hydrates the skin and provides nourishment. Suitable for all skin types.',
    longDescription: '<strong>Directions</strong>: This premium body lotion helps to hydrate and moisturize the skin, leaving it soft and supple. For the best results, use regularly for long-lasting hydration.',
  },
  3: {
    image: FootSoak,
    title: 'Foot Soak',
    category: 'skincare',
    price: '150.00',
    priceSize: '(150 g)',
    description: 'Soothes and relaxes muscles, cleanses feet, and prevents bacterial growth.',
    description1: '<strong>Ingredients</strong>: Epsom Salt, Baking Soda, Himalayan Pink Salt, Lavender Essential Oil.',
    description2: '<strong>Benefits</strong>: Soothes muscles, cleans feet, prevents bacterial growth, and reduces swelling.',
    longDescription: '<strong>Directions</strong>: Add a spoonful of foot soak to warm water. Stir and soak your feet for 20 minutes. Wash off and pat dry.',
  },
  4: {
    image: BathPowder,
    title: 'Herbal Bath Powder',
    category: 'skincare',
    price: '300.00',
    priceSize: '(200 g)',
    description: 'Herbal bath powder that makes your skin feel soft and fresh throughout the day.',
    description1: '<strong>Ingredients</strong>: Rose Petals, Vettiver, Marikolundhu, Koraikilangu, Vasambu, Karboga Arisi, Avarampoo, Poolan Kilangu, Green Gram, Hibiscus, Magilam Poo.',
    description2: '<strong>Benefits</strong>: Keeps your skin fresh and soft. Made with 100% herbal ingredients.',
    longDescription: '<strong>Directions</strong>: Take the required amount of powder, add water to make a paste, gently massage onto your body, and rinse. Can be used daily by both men and women.',
  },
  5: {
    image: BodyButter,
    title: 'Body Butter Cream',
    category: 'skincare',
    price: '350.00',
    priceSize: '(100 g)',
    description: 'Formulated to deeply moisturize and hydrate dry skin, leaving it soft and smooth.',
    description1: '<strong>Ingredients</strong>: Almond oil, Shea butter, Veg Glycerine, E Wax, Cetyl Alcohol, Aqua, Iscaguard Peg, Lavender Essential Oil.',
    description2: '<strong>Benefits</strong>: Provides intense hydration, nourishing and softening dry skin.',
    longDescription: '<strong>Directions</strong>: This luxurious body butter is packed with natural ingredients like almond oil and shea butter to deeply hydrate and nourish the skin, leaving it feeling smooth and supple.',
  },
  6: {
    image: LipButter,
    title: 'Lip Butter Cream',
    category: 'skincare',
    price: '110.00',
    priceSize: '(15 g)',
    description: 'Lip butter that moisturizes and soothes dry and chapped lips, leaving them soft and nourished.',
    description1: '<strong>Ingredients</strong>: Beeswax, Shea Butter, Virgin Coconut Oil, Olive Oil, Almond Oil.',
    description2: '<strong>Benefits</strong>: Provides intense moisturization to lips, leaving them smooth and hydrated.',
    longDescription: '<strong>Directions</strong>: Apply with fingertips for smooth and moisturized lips. Use regularly for best results.',
  },
  7: {
    image: FaceCream,
    title: 'Face Cream',
    category: 'skincare',
    price: '325.00',
    priceSize: '(50 g)',
    description: 'Nourishes the skin, reduces inflammation, and promotes a glowing complexion.',
    description1: '<strong>Ingredients</strong>: Licorice Extract, Fresh Almond Milk, Rose Hydrosol, Sweet Almond Oil, Virgin Coconut Oil, Emulsifying Wax, Cetyl Alcohol.',
    description2: '<strong>Benefits</strong>: Reduces inflammation and aging signs, and makes the skin soft and glowing.',
    longDescription: '<strong>Directions</strong>: Wash your face and apply a small amount of cream on your face and neck. Use regularly for best results.',
  },
  8: {
    image: FootButter,
    title: 'Foot Butter Cream',
    category: 'skincare',
    price: '200.00',
    priceSize: '(50 g)',
    description: 'Hydrates, softens, and heals cracked feet, leaving them smooth and nourished.',
    description1: '<strong>Ingredients</strong>: Shea Butter, Coconut Oil, Beeswax, Avocado Oil, Tea Tree Oil, Peppermint Oil, Eucalyptus Oil.',
    description2: '<strong>Benefits</strong>: Hydrates the skin, removes roughness, heals cracks, and nourishes the feet.',
    longDescription: '<strong>Directions</strong>: Massage gently onto feet and soles until fully absorbed. Best used before bedtime. Use regularly for best results.',
  },
  9: {
    image: Kajal,
    title: 'Kajal',
    category: 'eyecare',
    price: '200.00',
    priceSize: '(10 g)',
    description: 'A natural kajal that enhances and defines your eyes with a smooth, long-lasting finish.',
    description1: '<strong>Ingredients</strong>: Natural Khol, Ghee, Castor Oil, Almond Oil, Beeswax, Shea Butter.',
    description2: '<strong>Benefits</strong>: Smudge-proof, long-lasting, and made from 100% natural ingredients. Gentle on the eyes.',
    longDescription: '<strong>Directions</strong>: Our kajal is made with natural ingredients to give you a long-lasting, smooth finish. It enhances and defines the eyes, perfect for everyday use or special occasions.',
  },
  10: {
    image: LipBalm,
    title: 'Lip Balm',
    category: 'skincare',
    price: '99.00',
    priceSize: '(5 g)',
    description: 'Lip balm that provides deep moisturization and protects your lips from dryness.',
    description1: '<strong>Flavours</strong>: Beetroot, Vanilla, Strawberry, Rose, Normal.',
    description2: '<strong>Ingredients</strong>: Beeswax, Virgin Coconut Oil, Avocado Oil, Almond Oil, Cocoa Butter, Shea Butter, Vitamin E.',
    longDescription: '<strong>Directions</strong>: Apply with your fingertips for smooth and moisturized lips. Use regularly and as often as desired.',
  },
  11: {
    image: Nattusakkarai,
    title: 'Fresh Nattu Sakkarai',
    category: 'Food',
    price: '80.00',
    priceSize: '(1KG)',
    description: '100% organic jaggery powder, perfect for sweets and celebrations.',
    description1: 'Traditional organic jaggery powder from 100% natural ingredients.',
    description2: 'Made from the finest ingredients for the sweetest results.',
    longDescription: 'Our traditional organic jaggery powder is perfect for making sweets and adding a natural sweetness to your recipes. Ideal for celebrations and special occasions.',
  },
};
